import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DialogCloseButton from '../DialogCloseButton';
import DateField from '../DateField';
import Rails from '@rails/ujs';
import {notify} from 'react-notify-toast';

export default class UsersSearchDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      createdFrom: props.filters.createdFrom,
      createdTo: props.filters.createdFrom,
      updatedFrom: props.filters.updatedFrom,
      updatedTo: props.filters.updatedTo,
      adminId: props.filters.adminId,
      admins: []
    };
  }

  componentDidMount() {
    Rails.ajax({
      url: '/admins.json',
      type: 'get',
      success: admins => {
        this.setState({admins});
      },
      error: error => {
        this.setState({admins: []});
        notify.show(error, 'error');
      }
    });
  }

  renderHealthcareProviders = () =>
    <select
      onChange={e => this.setState({adminId: e.target.value})}
      value={this.state.adminId}
    >
      <option/>
      <option value='nil'>- Unassigned -</option>
      {this.state.admins.map(admin =>
        <option
          key={admin.id}
          value={admin.id}
        >
          {admin.fullName}
        </option>)}
    </select>;

  handleClearFilters = () => {
    const filters = {createdFrom: null, createdTo: null, updatedFrom: null, updatedTo: null, adminId: []};
    this.setState({...filters}, () => this.props.callback({}));
  };

  handleSubmit = e => {
    this.props.callback(this.state);
    e.preventDefault();
  };

  render() {
    return (
      <div
        className='reveal'
        id='search-filters-dialog'
      >
        <p className='lead'>Enable Search Filters</p>
        <form
          id='search-form'
          onSubmit={this.handleSubmit}
        >
          <label><b>Create Date Range</b></label>
          <div className='grid-x grid-margin-x'>
            <div className='auto cell'>
              <DateField
                name='createdFrom'
                onChange={createdFrom => this.setState({createdFrom})}
                value={this.state.createdFrom}
              />
            </div>
            <div className='shrink cell'>
              <label className='middle lead'>to</label>
            </div>
            <div className='auto cell'>
              <DateField
                name='createdTo'
                onChange={createdTo => this.setState({createdTo})}
                value={this.state.createdTo}
              />
            </div>
          </div>

          <label><b>Last Active Date Range</b></label>
          <div className='grid-x grid-margin-x'>
            <div className='auto cell'>
              <DateField
                name='updatedFrom'
                onChange={updatedFrom => this.setState({updatedFrom})}
                value={this.state.updatedFrom}
              />
            </div>
            <div className='shrink cell'>
              <label className='middle lead'>to</label>
            </div>
            <div className='auto cell'>
              <DateField
                name='updatedTo'
                onChange={updatedTo => this.setState({updatedTo})}
                value={this.state.updatedTo}
              />
            </div>
          </div>

          <label><b>Healthcare Providers</b></label>
          {this.renderHealthcareProviders()}

          <div className='grid-x grid-margin-x align-justify'>
            <div className='shrink cell'>
              <button
                className='margin-bottom-0 secondary button'
                data-close=''
                onClick={this.handleClearFilters}
                type='button'
              >
                <i className='fa fa-times fa-fw'/>Clear Filters
              </button>
            </div>
            <div className='auto cell'>
              <button
                className='expanded margin-bottom-0 primary button'
                data-close=''
                type='submit'
              >
                <i className='fa fa-filter fa-fw'/>Apply Filters
              </button>
            </div>
          </div>
        </form>

        <DialogCloseButton/>
      </div>
    );
  }
}

UsersSearchDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};