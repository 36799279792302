import React from 'react';

const DialogCloseButton = () =>
  <button
    className='close-button'
    data-close=''
    type='button'
  >
    <span aria-hidden='true'><i className='fa fa-close'/> </span>
  </button>;

export default DialogCloseButton;