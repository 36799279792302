import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class Pagination extends PureComponent {
  getLastPage = () => Math.ceil(this.props.totalCount / this.props.recordsPerPage);

  handleFirstPage = () => {
    this.props.callback(1);
  };

  handlePrevPage = () => {
    if (this.props.page > 1) {
      this.props.callback(this.props.page - 1);
    }
  };

  goToPage = page => {
    this.props.callback(page);
  };

  handleNextPage = () => {
    if (this.props.page < this.getLastPage()) {
      this.props.callback(this.props.page + 1);
    }
  };

  handleLastPage = () => {
    this.props.callback(this.getLastPage());
  };

  renderFirstPage = () =>
    <a
      className={this.props.page > 1 ? '' : 'disabled'}
      onClick={this.handleFirstPage}
    >First
    </a>;

  renderPrevPage = () =>
    <a
      className={this.props.page > 1 ? '' : 'disabled'}
      onClick={this.handlePrevPage}
    >Prev
    </a>;

  renderNextPage = () =>
    <a
      className={this.props.page < this.getLastPage() ? '' : 'disabled'}
      onClick={this.handleNextPage}
    >Next
    </a>;

  renderLastPage = () =>
    <a
      className={this.props.page < this.getLastPage() ? '' : 'disabled'}
      onClick={this.handleLastPage}
    >Last
    </a>;

  render() {
    return this.getLastPage() > 1 &&
            <div className='text-center'>
              <ul
                aria-label='Pagination'
                className='pagination'
                role='pagination'
              >
                <li>{this.renderFirstPage()}</li>
                <li className='pagination-previous'>{this.renderPrevPage()}</li>
                {this.props.page > 2 &&
                <li>
                  <a onClick={() => this.goToPage(this.props.page - 2)}>{this.props.page - 2}</a>
                </li>}
                {this.props.page > 1 &&
                <li>
                  <a onClick={() => this.goToPage(this.props.page - 1)}>{this.props.page - 1}</a>
                </li>}
                <li className='current'>{this.props.page}</li>
                {this.props.page < this.getLastPage() &&
                <li>
                  <a onClick={() => this.goToPage(this.props.page + 1)}>{this.props.page + 1}</a>
                </li>}
                {this.props.page < this.getLastPage() - 1 &&
                <li>
                  <a onClick={() => this.goToPage(this.props.page + 2)}>{this.props.page + 2}</a>
                </li>}
                <li className='pagination-next'>{this.renderNextPage()}</li>
                <li>{this.renderLastPage()}</li>
              </ul>
            </div>;
  }
}

Pagination.propTypes = {
  callback: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired
};