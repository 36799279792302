import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const UsersTableHeader = () =>
  <div className='curved primary thead'>
    <div className='grid-x grid-margin-x align-middle'>
      <div className='auto cell'>
        <small>Participant ID</small>
      </div>
      <div className='auto cell'>
        <small>Create Date</small>
      </div>
      <div className='auto cell'>
        <small>Last Active Date</small>
      </div>
      <div className='auto cell'>
        <small>Healthcare Provider</small>
      </div>
      <div className='auto cell'/>
    </div>
  </div>;

const UsersTableRow = ({user}) =>
  <div
    className='tr'
  >
    <div className='grid-x grid-margin-x align-middle align-justify'>
      <div className='small-6 medium-auto cell text-truncate'>
        <div className='text-truncate'>{user.participantId}</div>
      </div>
      <div className='small-6 medium-auto cell'>
        <div className='text-truncate'>{moment(user.createdAt).format('lll')}</div>
      </div>
      <div className='small-6 medium-auto cell'>
        <div className='text-truncate'>{moment(user.updatedAt).format('lll')}</div>
      </div>
      <div className='small-6 medium-auto cell'>
        {user.inInterventionGroup && user.healthcareProvider}
      </div>
      <div className='small-6 medium-auto cell text-right'>
        <a
          className='clear primary button'
          data-remote='true'
          href={`/users/${user.id}/edit/`}
        >
          Edit
        </a>
        <a
          className='clear alert button'
          data-confirm='Are you sure?'
          data-method='delete'
          data-remote='true'
          href={`/users/${user.id}`}
          rel='nofollow'
        >
          Delete
        </a>
      </div>
    </div>
  </div>;

UsersTableRow.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    inInterventionGroup: PropTypes.bool.isRequired,
    healthcareProvider: PropTypes.string,
    participantId: PropTypes.string.isRequired
  })
};

const UsersTable = ({users}) =>
  <div className='card'>
    <div className='curved table'>
      {users.length > 0 && <UsersTableHeader/>}
      {users.map(user =>
        <UsersTableRow
          key={user.id}
          user={user}
        />)}
    </div>
  </div>;

UsersTable.propTypes = {
  users: PropTypes.array.isRequired
};

export default UsersTable;